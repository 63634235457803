import { DeviceCompliancePolicy, DeviceCompliancePolicyAssignment } from '@microsoft/microsoft-graph-types-beta';
import { createAction, props } from '@ngrx/store';
import { PolicyAssignment } from './modet';

export const loadCompliancePolicies = createAction(
    '[Graph/Compliance Policies] Load Compliance Policy',
    props<{ _tenant: string }>(),
);

export const loadWindows10CompliancePoliciesSuccess = createAction(
    '[Graph/Compliance Policies] Load Compliance Policies Success',
    props<{ _tenant: string; data: PolicyAssignment[] }>(),
);

export const loadWindows10CompliancePoliciesFailure = createAction(
    '[Graph/Compliance Policies] Load Compliance Policy Failure',
    props<{ _tenant: string; error: any }>(),
);

export const reloadCompliancePolicies = createAction(
    '[Graph/Compliance Policies] Reload Compliance Policy',
    props<{ _tenant: string }>(),
);

export const reloadWindows10CompliancePoliciesSuccess = createAction(
    '[Graph/Compliance Policies] Reload Compliance Policies Success',
    props<{ _tenant: string; data: PolicyAssignment[] }>(),
);

export const reloadWindows10CompliancePoliciesFailure = createAction(
    '[Graph/Compliance Policies] Reload Compliance Policy Failure',
    props<{ _tenant: string; error: any }>(),
);

export const createWidows10CompliancePolicy = createAction(
    '[Graph/Compliance policies/] Create Compliance Policy ',
    props<{ _tenant: string; policy: DeviceCompliancePolicy; assignments?: DeviceCompliancePolicyAssignment[] }>(),
);

export const createWidows10CompliancePolicySuccess = createAction(
    '[Graph/Compliance Policies] Create Compliance Policy Success',
    props<{ _tenant: string; policy: PolicyAssignment }>(),
);

export const createWidows10CompliancePolicyFailure = createAction(
    '[Graph/Compliance Policies] Create Compliance Policy Failure',
    props<{ _tenant: string; error: any }>(),
);

export const updateWidows10CompliancePolicy = createAction(
    '[Graph/Compliance policies/] Update Compliance Policy ',
    props<{ _tenant: string; policy: any; assignments?: DeviceCompliancePolicyAssignment[] }>(),
);

export const updateWidows10CompliancePolicySuccess = createAction(
    '[Graph/Compliance Policies] Update Compliance Policy Success',
    props<{ _tenant: string; policy: PolicyAssignment }>(),
);

export const updateWidows10CompliancePolicyFailure = createAction(
    '[Graph/Compliance Policies] Update Compliance Policy Failure',
    props<{ _tenant: string; error: any }>(),
);

export const deleteWidows10CompliancePolicy = createAction(
    '[Graph/Compliance policies/] Delete Compliance Policy ',
    props<{ _tenant: string; id: string }>(),
);

export const deleteWidows10CompliancePolicySuccess = createAction(
    '[Graph/Compliance Policies] Delete Compliance Policy Success',
    props<{ _tenant: string; id: string }>(),
);

export const deleteWidows10CompliancePolicyFailure = createAction(
    '[Graph/Compliance Policies] Delete Compliance Policy Failure',
    props<{ _tenant: string; error: any }>(),
);
